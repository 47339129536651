<!-- 小程序配置 -->

<template>
	<div class="applet main-cnt">
		<div class="content">
			<common-table ref="chargeTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
				:apiName="SystemManage.getMiniLists" :columns="tableColumns" @addAssociation="addAssociation"
				@onPowStation="onPowStation" @updateAssociation="updateAssociation" @adminStaff="adminStaff">

				<template #pay_config='{row}'>
					<div class="block showAlbum" @click="onPayDetail(row)">
						{{row.pay_config.length}}
					</div>
				</template>
			</common-table>
		</div>
		<!-- 支付配置详情 -->
		<w-dialog ref="refPayConfigDialog" class="add-dialog" title="支付配置详情" width="35%" btnWidth="140px" top="10vh"
			:hideFooter="true">
			<div>
				<div class="s-m-b">微信商户号</div>
				<el-input v-model="payConfigDetails.pay_config.mp_wx_mch_id" readonly class="cell-50 s-m-b">
				</el-input>
			</div>
			<div>
				<div class="s-m-b s-m-t">备注说明</div>
				<el-input v-model="payConfigDetails.pay_config.mp_mch_name" type="textarea" rows="3" readonly>
				</el-input>
			</div>
		</w-dialog>

		<!-- 添加关联电站弹框 -->
		<w-dialog ref="refDialog" class="add-dialog" title="新增关联" width="50%" btnWidth="140px" top="10vh"
			@wConfirm="onConfirmStation">
			<el-row>
				<el-col :span="7" class="col">
					<div class="bg-title b-t-l-r">小程序名称</div>
					<div class="contents border-bottom">{{ currentRow.mi_name }}</div>
				</el-col>
				<el-col :span="7" class="col">
					<div class="bg-title">小程序ID</div>
					<div class="contents border-bottom">{{ currentRow.mi_id }}</div>
				</el-col>
				<el-col :span="10" class="col">
					<div class="bg-title">小程序应用ID</div>
					<el-tooltip class="box-item" effect="light" :content="currentRow.mi_uuid" placement="bottom">
						<div class="contents border-bottom">{{ currentRow.mi_uuid }}</div>
					</el-tooltip>
				</el-col>
			</el-row>
			<div class="pow-title">关联电站</div>
			<el-table :data="tableStation" stripe style="width: 100%" element-loading-text="数据加载中"
				tooltip-effect="light" @selection-change="stationSelectionChange" height='450'
				:row-key="getStationKeys">
				<el-table-column type="selection" width="55" :reserve-selection="true" />
				<el-table-column prop="s_name" label="电站名称" show-overflow-tooltip />
				<el-table-column prop="s_no" label="电站编号" show-overflow-tooltip />
				<el-table-column prop="region" label="区域" show-overflow-tooltip />
			</el-table>
			<div class="s-m-t">已选<span class="select-quant title-num-green">{{ selectStation.length }}</span></div>
		</w-dialog>

		<!-- 关联电站 -->
		<w-dialog ref="refPowStationDialog" class="add-dialog" title="关联电站" width="50%" btnWidth="140px" top="10vh"
			:hideFooter="true">
			<el-input v-model="extraParameStation.keywords" placeholder="请输入电站名称或编号" clearable @input="powStationChange"
				class="cell-30 s-m-b">
			</el-input>
			<common-table ref="powStationTable" :ispaging="false" :apiName="SystemManage.getMiniStationLists"
				:columns="powStationColumns" @onPowStationBtn="onPowStationBtn" tableHeight="450"
				:extraParame="extraParameStation">
			</common-table>
		</w-dialog>

		<!-- 关联支付 -->
		<w-dialog ref="refPay" class="add-dialog" title="关联支付" width="50%" btnWidth="140px" top="10vh"
			:hideFooter="true">
			<common-table ref="payTable" :ispaging="false" :columns="payColumns" :tableData="payTableData"
				tableHeight="300">
			</common-table>
		</w-dialog>


		<!-- 编辑弹框 -->
		<w-dialog ref="refUpdateDialog" class="add-dialog" :title="isPayment ? '编辑管理员' : '编辑支付配置'" width="50%" btnWidth="140px" top="10vh"
			@wConfirm="onConfirmUpdate">
			<div class="pow-title  text-theme">小程序名称:{{ updateRow.mi_name }}</div>
			<div v-if="isPayment">
				<div class="pow-title b-t-l-r">平台费用</div>
				<el-input v-model="updateRow.mi_platform_fee" placeholder="请输入平台费用"></el-input>
				<div class="pow-title">管理人员</div>
				<el-select  clearable filterable remote reserve-keyword v-model="managerKeywords" placeholder="请输入用户姓名"
					remote-show-suffix :remote-method="searchManage" @change="changeManage">
					<el-option v-for="(item,index) in manageList" :key="index" :label="item.u_name+'-'+item.u_tel+'-'+item.user_type_text" :value="item.u_id" />
				</el-select>
				<div class="contents border-bottom">
					<el-button class="active-skin"  v-for="(item,i) in onfirmManage" :key="i">
					<div class="del-btn" @click="delManage(i)">x</div>
					{{item.u_name}}</el-button>
				</div>
			</div>
			<div v-else>
				<div class="pow-title">支付配置</div>
				<el-select  clearable filterable remote reserve-keyword v-model="mchPayConfigKeywords" placeholder="请输入商户号"
					remote-show-suffix :remote-method="searchMchPayConfigList" @change="changeMchPay">
					<el-option v-for="(item,index) in mchPayList" :key="index" :label="item.mp_wx_mch_id+'-'+item.mp_mch_name" :value="item.mp_id" />
				</el-select>
				<common-table ref="refUpdateTable" :ispaging="false" :columns="updateColumns" :tableData="updateTableData"
					tableHeight="300" @statusChange="statusChange" @untieMerchant="untieMerchant">
					<template #pd_percent="{row}">
						<input type="text" class="update-input" v-model="row.pd_percent" />%
					</template>
				</common-table>
			</div>
		</w-dialog>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		computed,
		watch,
		nextTick
	} from "vue";
	import {
		SystemManage
	} from "@/plugins/api.js";
	import {
		unique,
		objToStirng
	} from "@/utils/common.js";
	import {
		ElMessage,
		ElMessageBox
	} from "element-plus";
	import {
		useStore
	} from "vuex";

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	watch(
		() => menuTokens.value,
		(data) => {
			if (data.length) {
				authData.value = data;
			}
		}, {
			deep: true,
			immediate: true,
		}
	);
	/** 表格对象 */
	const chargeTable = ref(null);
	/** 表格配置数据 */
	const tableColumns = ref([{
			prop: "mi_id",
			label: "小程序ID",
			color: "--text-color",
		},
		{
			prop: "mi_name",
			label: "小程序名称",
			color: "--text-color",
		},
		{
			type: "customRender",
			prop: "pay_config",
			label: "关联支付",
			minWidth: 100,
			active: "onPayDetail",
			token: "members",
		},
		// {
		// 	type: "block",
		// 	prop: "mi_mp_id",
		// 	label: "支付配置ID",
		// 	minWidth: 60,
		// 	active: "onPowDetails",
		// 	token: "members",
		// },
		{
			prop: "mi_platform_fee",
			label: "平台费用(元/年)",
			color: "--text-color",
		},
		{
			prop: "manager_list",
			label: "管理员",
			color: "--text-color",
		},
		{
			type: "block",
			prop: "station_count",
			label: "关联电站",
			minWidth: 60,
			active: "onPowStation",
			token: "members",
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 300,
			bottons: [{
				name: "添加",
				action: "addAssociation",
				token: "members",
			}, {
				name: "编辑管理人员",
				action: "adminStaff",
				token: "members",
				className: "admin-staff"
			},
			{
				name: "编辑商户号",
				action: "updateAssociation",
				token: "members",
			}, ],
		},
	]);

	const payConfigDetails = ref({}); // 支付配置详情
	const refPayConfigDialog = ref(null); // 支付配置弹框对象
	const refDialog = ref(null); // 弹框对象
	const currentRow = ref(null); // 当前操作 行数据
	const tableStation = ref([]); // 表格数据
	const selectStation = ref([]); // 选中绑定电站数据
	/** 关联电站 */
	const refPowStationDialog = ref(null); // 弹框对象
	const powStationTable = ref(null); // 表格对象
	
	const refUpdateTable= ref(null) //编辑小程序表格
	const isPayment = ref(false);  // 是否编辑支付配置
	
	const extraParameStation = ref({
		mi_id: '',
		keywords: '',
		type: 1
	}); // 自定义参数
	const powStationColumns = ref([ // 表格配置
		{
			prop: "s_name",
			label: "电站名称",
			color: "--text-color",
		},
		{
			prop: "s_no",
			label: "电站编号",
			color: "--text-color",
		},
		{
			prop: "province_name",
			label: "区域",
			color: "--text-color",
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 100,
			bottons: [{
				name: "解绑",
				action: "onPowStationBtn",
				token: "members",
			}, ],
		},
	]);
	/**
	 * 
	 * 支付配置ID按钮
	 * 
	 * */
	// const onPowDetails = (row) => {
	// 	payConfigDetails.value = row;
	// 	refPayConfigDialog.value.show();
	// }

	/**
	 * 关联支付
	 */
	const payColumns = ref([{
			prop: "mp_wx_mch_id",
			label: "商户号",
			color: "--text-color",
		},
		{
			prop: "pd_percent",
			label: "分成占比",
			color: "--text-color",
		},
		{
			prop: "mp_mch_name",
			label: "备注",
			color: "--text-color",
		},
	])
	const refPay = ref(null)
	const payTable = ref(null)
	const payTableData = ref([])
	const onPayDetail = row => {
		SystemManage.platEditInfo({
			mi_id: row.mi_id
		}).then(res => {
			refPay.value.show()
			if (res.Code == 200 && res.Data) {
				payTableData.value = res.Data.pay_list
			}
			if (payTableData.value.length != 0) {
				nextTick(() => {
					payTable.value.tableLoad()
				})
			}

		})

	}

	/**
	 * 
	 * 添加按钮
	 * 
	 * */
	const addAssociation = (row) => {
		currentRow.value = row;
		SystemManage.getMiniStationLists({
			mi_id: row.mi_id,
			type: 2
		}).then((res) => {
			if (res.Code === 200) {
				tableStation.value = res.Data ? res.Data : [];
				if (tableStation.value.length > 0) {
					tableStation.value.forEach(item => {
						item.region = item.city_name + item.county_name;
					})
				}
				selectStation.value = [];
				refDialog.value.show();
			} else {
				let msg = res.Message ? res.Message : "权限修改失败！";
				ElMessage.error(msg);
			}
		});
	}

	/***********编辑小程序start*********/
	const managerKeywords= ref('')
	const searchManage = (e) =>{
		SystemManage.managerList({keywords:e,limit:10000}).then(res =>{
			if(res.Code == 200){
				manageList.value = res.Data.list
			}
		})
	}
	const onfirmManage = ref([])
	const changeManage = (e) =>{
		if(manageList.value){
			const row = manageList.value.filter(res =>{
				if(res.u_id == e){
					return res
				}
			})
			if(row && row[0]){
				onfirmManage.value.push(row[0])
				onfirmManage.value = unique(onfirmManage.value)
			}
		}
	}
	const delManage = (i) =>{
		onfirmManage.value.splice(i,1)
	}
	const manageList = ref([])
	const refUpdateDialog = ref(null)
	const updateRow = ref({ids:''})
	const updateTableData = ref([])
	const updateColumns = ref([{
			prop: "mp_wx_mch_id",
			label: "商户号",
			color: "--text-color",
		},
		{
			prop: "mp_mch_name",
			label: "商户名称",
			color: "--text-color",
		},
		{
			prop: "pd_percent",
			label: "分成占比",
			color: "--text-color",
			type:'customRender'
		},
		{
			prop: "mp_remark",
			label: "备注",
			color: "--text-color",
		},
		{
			prop: "mp_tip",
			label: "是否默认",
			color: "--text-color",
			type:'switch',
			token:'members'
		}, {
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 100,
			bottons: [{
				name: "解绑",
				action: "untieMerchant",
				token: "members",
			}],
		}
	])
	//获取编辑数据
	const updateAssociation = (row) => {
		refUpdateDialog.value.show()
		updateTableData.value = [];
		mchPayConfigKeywords.value = '';
		mchPayList.value = [];
		isPayment.value = false;
		miniEditInfo(row);
		
	}
	/**
	 * 
	 * 编辑管理人员
	 * 
	 * **/
	const adminStaff = (row) => {
		isPayment.value = true;
		refUpdateDialog.value.show()
		miniEditInfo(row);
	}
	const miniEditInfo = (row) => {
		SystemManage.miniEditInfo({mi_id:row.mi_id}).then(res =>{
			if(res.Code == 200){
				updateRow.value = res.Data.mini;
				onfirmManage.value = res.Data.manager_list;
				updateTableData.value = res.Data.pay_list;
			}
			if(!isPayment.value) {
				if(updateTableData.value.length != 0){
					nextTick(() => {
						refUpdateTable.value.tableLoad()
					})
				}
			}
		})
	}
	//确认编辑
	const onConfirmUpdate = () => {
		let formData = {
			fee:updateRow.value.mi_platform_fee,
			manager:objToStirng(onfirmManage.value,'u_id'),
			mi_id:updateRow.value.mi_id,
			pays:updateTableData.value
		}
		let pd_all =  updateTableData.value.reduce((prev, cur) =>{
			return prev + parseInt(cur.pd_percent)
		},0)
		if(!isPayment.value) {
			if(!pd_all){
				ElMessage.error("请填写完整分成占比");
				return
			}
			if(pd_all != 100){
				ElMessage.error("分成占必须100%");
				return
			}
		}
		
		SystemManage.miniEdit(formData).then(res =>{
			if(res.Code ==200){
				ElMessage.success(res.Message);
				setTimeout(()=>{
					refUpdateDialog.value.close()
					chargeTable.value.tableLoad();
				},1000)
				
			}else{
				ElMessage.error(res.Message);
			}
		})
	}
	const mchPayConfigKeywords =ref('')
	const mchPayList = ref([])
	const searchMchPayConfigList = (e)=>{
		SystemManage.mchPayConfigList({keywords:e,limit:10000}).then(res =>{
			if(res.Code == 200){
				mchPayList.value = res.Data.list
			}
		})
	}
	
	const changeMchPay=(e) =>{
		if(mchPayList.value){
			const row = mchPayList.value.filter(res =>{
				if(res.mp_id == e){
					return res
				}
			})
			if(row && row[0]){
				updateTableData.value.push(row[0])
			}
			updateTableData.value = unique(updateTableData.value)
		}
		nextTick(() => {
			refUpdateTable.value.tableLoad()
		})
		
	}
	const statusChange = (e) =>{
		updateTableData.value.map(res =>{
			res.mp_tip = 2
		})
		if(e.mp_tip == 1){
			e.mp_tip = 2
		}else if(e.mp_tip == 2){
			e.mp_tip = 1
		}
		nextTick(() => {
			refUpdateTable.value.tableLoad()
		})
	}
	//解绑商户
	const untieMerchant = (e,i) =>{
		updateTableData.value.splice(i,1)
	}
	/***********编辑小程序end*********/



	const getStationKeys = (row) => {
		return row.mp_id;
	}
	/**
	 * 
	 * 选择绑定电站
	 * 
	 * */
	const stationSelectionChange = (val) => {
		selectStation.value = val;
	}
	/**
	 * 
	 * 绑定电站确认按钮
	 * 
	 * */
	const onConfirmStation = () => {
		if (selectStation.value.length == 0) {
			ElMessage.error("请选择绑定数据");
			return;
		}
		refDialog.value.isLoading = true;
		// 处理id
		let arr = [];
		selectStation.value.forEach((item) => {
			arr = arr.concat(item.s_id);
		});
		let params = {
			mi_id: currentRow.value.mi_id,
			bind_sid: arr.join(","),
		};
		SystemManage.bindMiniStation(params).then((res) => {
			refDialog.value.isLoading = false;
			if (res.Code === 200) {
				refDialog.value.close();
				chargeTable.value.tableLoad();
				ElMessage.success("绑定成功！");
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 点击关联电站
	 * 
	 * */
	const onPowStation = (row) => {
		refPowStationDialog.value.show();
		nextTick(() => {
			extraParameStation.value.mi_id = row.mi_id;
			powStationTable.value.tableLoad();
		});
	}
	/**
	 * 
	 * 搜索电站列表
	 * 
	 * */
	const powStationChange = () => {
		powStationTable.value.tableLoad();
	}
	/**
	 * 
	 * 关联电站解绑
	 * 
	 * */
	const onPowStationBtn = (row) => {
		ElMessageBox.confirm(
				`确认将该电站和小程序解绑，解绑后小程序将无法查看该站！`,
				'解绑确认', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
				}
			)
			.then(() => {
				SystemManage.unBindMiniStation({
					s_id: row.s_mi_id
				}).then((res) => {
					if (res.Code === 200) {
						ElMessage.success(`解绑成功`); // 成功提示
						refPowStationDialog.value.close();
						chargeTable.value.tableLoad();
					} else {
						ElMessage.error(res.Message);
					}
				});
			})
			.catch(() => {

			})
	}

	onMounted(() => {
		chargeTable.value.tableLoad();
	});
</script>

<style lang="scss" scoped>
	.el-textarea__inner {
		background-color: var(--search-uncheck-bg-color);
		border: none;
		color: var(--text-color);
	}
	.update-input{
		border: none;
		width: 40px;
		border-bottom: 1px solid #eee;
	}
	.active-skin {
	  color: var(--theme-color) !important;
	  background-color: var(--search-bg-color) !important;
	  position: relative;
	  .del-btn{
		  position: absolute;
		  right: 0;
		  top: 0;
		  height: 15px;
		  width: 20px;
		  border-radius: 0 0 0 50%;
		  background-color: rgba(0, 0, 0, 0.5);
		  color: #fff;
	  }
	}

	.text-theme {
		color: #00d39b !important;
	}

	.pow-title {
		font-size: 15px;
		color: var(--text-color);
		padding: 6px 0;
	}

	.bg-title {
		background-color: var(--search-bg-color);
		padding: 10px;
		min-width: 120px;
		color: var(--text-third-color);
		font-weight: bold;
	}

	.contents {
		padding: 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		-webkit-line-clamp: 1;
		color: var(--text-color);
	}

	.el-row {
		border-radius: 5px;
		border: 1px solid var(--border-color);
		margin-bottom: 10px;
	}

	.col {
		display: flex;
	}

	.border-bottom {
		border-bottom: 1px solid var(--border-color);
	}

	.applet {
		font-family: "Source Han Sans CN";

		.content {
			padding: 20px;
		}

		.add-dialog {
			.el-dialog__body {
				padding: 10px 15px 15px 30px;
			}
		}
	}
</style>